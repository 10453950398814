import { Component, Prop } from 'vue-property-decorator';
import DateRangePicker from 'vue2-daterange-picker';
import { DateTime } from 'luxon';
import DatepickerRange, { DateRangeValues } from '@/components/ui/inputs/datepicker-range/datepickerRange.ts';
import {
  DATE_MODE_MONTHLY,
  IDateMode,
  prepareDateByMode,
  prepareDatepickerDate
} from '@/helpers/date.helper';
import isFunction from 'lodash/isFunction';

@Component({
  name: 'DatepickerRangeMode',
  components: { DateRangePicker }
})
export default class DatepickerRangeMode extends DatepickerRange {
  @Prop({
    required: false,
    default: () => DATE_MODE_MONTHLY
  })
  mode!: IDateMode;

  updateDateValues(dateRange: DateRangeValues) {
    const dateRangeStartDate = prepareDateByMode(this.mode, dateRange.startDate as Date);
    const dateRangeEndDate = prepareDateByMode(this.mode, dateRange.endDate as Date, false);
    this.dateRange.startDate = dateRangeStartDate;
    this.dateRange.endDate = dateRangeEndDate;
    const startDate = prepareDatepickerDate(dateRangeStartDate);
    const endDate = prepareDatepickerDate(dateRangeEndDate, { hour: 23, minute: 59, second: 59, millisecond: 59 });

    this.$emit('update:startDate', startDate);
    this.$emit('update:endDate', endDate);

    if (this.$listeners.update && isFunction(this.$listeners.update)) {
      this.$listeners.update(startDate, endDate);
    }
  }

  initRanges() {
    const today = DateTime.local().toUTC().toJSDate();
    const last7days = DateTime.local().toUTC().minus({ days: 6 }).toJSDate();
    const last30days = DateTime.local().toUTC().minus({ days: 29 }).toJSDate();
    const thisMonthStart = DateTime.local().toUTC().startOf('month').toJSDate();
    const thisMonthEnd = DateTime.local().toUTC().endOf('month').toJSDate();
    const prevMonthStart = DateTime.local().toUTC().minus({ month: 1 }).startOf('month').toJSDate();
    const prevMonthEnd = DateTime.local().toUTC().minus({ month: 1 }).endOf('month').toJSDate();

    this.ranges = {
      [this.$t('datePicker.last7Days') as string]: [last7days, today],
      [this.$t('datePicker.last30Days') as string]: [last30days, today],
      [this.$t('datePicker.thisMonth') as string]: [thisMonthStart, thisMonthEnd],
      [this.$t('datePicker.lastMonth') as string]: [prevMonthStart, prevMonthEnd],
    };
  }
}
