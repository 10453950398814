import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DATE_MODE_CUSTOM_WEEKLY, DATE_MODE_MONTHLY, IDateMode } from '@/helpers/date.helper';
import { BFormRadioGroup } from 'bootstrap-vue';
import { isMobile } from '@/helpers/app.helper';
import { i18n } from '@/i18n';

interface IOptions {
  text: string,
  value: IDateMode
}

@Component({
  name: 'ModeSwitcher',
  components: {
    BFormRadioGroup
  }
})
export default class ModeSwitcher extends Vue {
  innerValue: IDateMode = DATE_MODE_CUSTOM_WEEKLY;
  modeOption: IOptions | null = null;

  @Prop({
    required: false,
    default: () => DATE_MODE_CUSTOM_WEEKLY
  })
  value!: IDateMode;

  @Prop({
    required: false,
    default: () => [
      { text: i18n.t('dateMode.weekly') as string, value: DATE_MODE_CUSTOM_WEEKLY },
      { text: i18n.t('dateMode.monthly') as string, value: DATE_MODE_MONTHLY },
    ]
  })
  options!: IOptions[];

  @Watch('innerValue')
  innerValueChange(newVal: IDateMode) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: IDateMode) {
    this.innerValue = newVal;
  }

  created() {
    this.innerValue = this.value;

    if (this.options.length) {
      this.modeOption = this.options.find(item => Number(item.value) === Number(this.value))!;
    }
  }

  get modeOptions(): IOptions[] {
    return this.options || []
  }

  get isMobile() {
    return isMobile();
  }

  onChange() {
    const index = this.options.findIndex(item => Number(item.value) === Number(this.innerValue));
    const nextIndex = index + 1 >= this.options.length ? 0 : index + 1;

    this.modeOption = this.options[nextIndex];
    this.innerValue = this.modeOption.value;
  }

  get modeName() {
    return this.modeOption?.text || '';
  }
}
