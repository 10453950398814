import { WorkBook, utils, write } from 'xlsx';
import { toArrayBuffer } from '@/helpers/string.helper';
import { saveAs } from 'file-saver';
import { http } from '@/api/http';
import { getFilterAsString } from '@/helpers/filter.helper';

export interface XlsxHelperOptions {
  sheetName?: string;
  isTable?: boolean;
}

export const exportToXls = (fileName: string, rows: any, options: XlsxHelperOptions = {}) => {
  const {
    sheetName = 'Sheet1',
    isTable = false,
  } = options
  const wb: WorkBook = {
    SheetNames: [],
    Sheets: {}
  };
  const ws: any = isTable ? utils.table_to_sheet(rows) : utils.json_to_sheet(rows);

  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;

  const data = write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary'
  });
  saveAs(new Blob([toArrayBuffer(data)], { type: 'application/octet-stream' }), fileName);
};

export const downloadToXls = (url: string, fileName: string): Promise<void> => {
  return http.get(url)
    .then(response => {
      const type = response.headers['content-type'];
      saveAs(new Blob([response.data], { type }), fileName);
    });
}

export const getXlsFilename = (filename: string, filter: any): string => {
  return `${filename}-${ getFilterAsString(filter) }.xlsx`;
}
