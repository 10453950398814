import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsProductBrandRequest } from '@/types/request/reports-request.types';
import { DateTime } from 'luxon';
import {
  DATE_FORMAT,
  DATE_MODE_CUSTOM_WEEKLY, DATE_MODE_DAILY,
  DATE_MODE_MONTHLY,
  DATEPICKER_FORMAT,
  getDateTitle,
} from '@/helpers/date.helper';
import DatepickerRangeMode from '@/components/ui/inputs/datepicker-range-mode/datepicker-range-mode.vue';
import AcosTacosWidget from '@/components/ui/acos-tacos-widget/acos-tacos-widget.vue';
import { BFormRadioGroup } from 'bootstrap-vue';
import { ProductBrandType } from '@/types/api/reports/product-brand.type';
import ExportXlsxBtn from '@/components/ui/buttons/export-xlsx-btn/export-xlsx-btn.vue';
import { exportToXls, getXlsFilename } from '@/helpers/xlsx.helper';
import { numberFormatter } from '@/helpers/string.helper';
import ModeSwitcher from '@/components/ui/mode-switcher/mode-switcher.vue';
import { debounceFilter } from '@/helpers/filter.helper';

const title = 'tacosAcosReport.title';

@Component({
  name: 'TacosAcosReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    DatepickerRangeMode,
    AcosTacosWidget,
    BFormRadioGroup,
    ExportXlsxBtn,
    ModeSwitcher,
  },
})
export default class TacosAcosReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();
  pageFilter: any = this.initPageFilter();
  saveFilter = true;

  @Action('getProductBrandReport', { namespace: 'reports' })
  getProductBrandReport!: (params?: ReportsProductBrandRequest) => Promise<ProductBrandType>;

  @Getter('getProductBrandReport', { namespace: 'reports' })
  productBrandReport!: ProductBrandType;

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;
    this.filter.type = this.$route.query.type || DATE_MODE_MONTHLY;
    this.pageFilter.tacosFrom = this.$route.query.tacosFrom || 20;
    this.getProductBrandReportRequest();
    this.debounceUpdateValue = debounceFilter(this.debounceUpdateValue);
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  getProductBrandReportRequest() {
    return this.getProductBrandReport(this.preparedFilter);
  }

  initPageFilter() {
    return {
      tacosFrom: this.$route.query.tacosFrom || 20,
      tacosTo: this.$route.query.tacosTo || 40,
      acosFrom: this.$route.query.acosFrom || 20,
      acosTo: this.$route.query.acosTo || 40,
    };
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      type: this.$route.query.type || null,
    };
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
    }
  }

  get modeOptions () {
    return [
      { text: this.$t('dateMode.daily'), value: DATE_MODE_DAILY },
      { text: this.$t('dateMode.weekly'), value: DATE_MODE_CUSTOM_WEEKLY },
      { text: this.$t('dateMode.monthly'), value: DATE_MODE_MONTHLY },
    ]
  }

  get productBrands() {
    return this.productBrandReport?.brands || [];
  }

  onExport() {
    if (this.productBrands.length) {
      const data: any = [];
      const fileName = getXlsFilename(this.$t(title) as string, this.filter);
      this.productBrands.forEach(brandItm => {
        brandItm.products.forEach(productItm => {
          Object.values(productItm.statistics).forEach(statValues => {
            data.push({
              [this.$t('common.period') as string]: getDateTitle(statValues.from, statValues.to, this.filter.type),
              [this.$t('brandProducts.brand') as string]: brandItm.brandName,
              [this.$t('brandProducts.product') as string]: productItm.productName,
              [this.$t('brandProducts.asin') as string]: productItm.asin,
              [this.$t('brandProducts.tacos') as string]: `${statValues.totalSales ? numberFormatter((statValues.spend / statValues.totalSales) * 100, { fractionPartCount: 0 }) : 0}%`,
              [this.$t('brandProducts.acos') as string]: `${statValues.ppcSales ? numberFormatter((statValues.spend / statValues.ppcSales) * 100, { fractionPartCount: 0 }) : 0}%`,
            })
          })
        });
      });
      exportToXls(fileName, data);
    }
  }

  debounceUpdateValue(name: string, value: string) {
    this.pageFilter[name] = value
    this.applyFilter()
  }
}
