import { Component, Prop, Vue } from 'vue-property-decorator';
import isFunction from 'lodash/isFunction';
import { BImg } from 'bootstrap-vue';

export interface ImageCellRendererParams {
  url: ((params: ImageCellRendererParams) => string) | string;
}

@Component({
  name: 'ImageCellRenderer',
  components: {
    BImg
  }
})
export default class ImageCellRenderer extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  params!: ImageCellRendererParams;

  @Prop({
    type: String,
    required: false,
    default: () => '100px'
  })
  height!: string;

  @Prop({
    type: String,
    required: false,
    default: () => '140px'
  })
  width!: string;

  get url(): string {
    if (isFunction(this.params.url)) {
      return this.params.url(this.params);
    }
    return this.params.url;
  }
}
