import { Component, Prop, Vue } from 'vue-property-decorator';
import Box from '@/components/ui/box/box.vue';
import { capitalizeFirstLetter } from '@/helpers/string.helper';
import { APP_KEY_CHART_PICKER, getSavedState, saveState } from '@/helpers/local-storage.helper';

@Component({
  name: 'ChartsPicker',
  components: {
    Box
  }
})
export default class ChartsPicker extends Vue {
  selectedChartName: any = null;

  @Prop({
    required: false,
    default: () => ''
  })
  title!: string;

  mounted() {
    const name = this.chartSlots.find(item => item === getSavedState(this.savedChartName)) || null;
    this.selectedChartName = name || Object.keys(this.$slots)?.[0] || null;
  }

  get chartSlots() {
    return Object.keys(this.$slots);
  }

  selectChartName(chartSlot: string) {
    this.selectedChartName = chartSlot;
    saveState(this.savedChartName, chartSlot);
  }

  formatItem(value: string) {
    return capitalizeFirstLetter(value);
  }

  onClickSlot() {
    this.$emit('click-slot', this.selectedChartName);
  }

  get savedChartName() {
    return `${APP_KEY_CHART_PICKER}-${this.$route.name}-${this.title}`;
  }
}
