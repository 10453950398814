import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateRangePicker from 'vue2-daterange-picker';
import { DateTime, Info } from 'luxon';
import { DATE_FORMAT, DATEPICKER_FORMAT, prepareDatepickerDate } from '@/helpers/date.helper';
import { Getter } from 'vuex-class';
import isFunction from 'lodash/isFunction';

export interface DateRangeValues {
  startDate: null | string | Date;
  endDate: null | string | Date;
}

@Component({
  components: { DateRangePicker }
})
export default class DatepickerRange extends Vue {
  dateRange: DateRangeValues = {
    startDate: null,
    endDate: null,
  };
  localeData: any;
  ranges: any;

  @Prop({
    required: false,
  })
  startDate?;

  @Prop({
    required: false,
  })
  endDate?;

  @Prop({
    type: String,
    required: false,
  })
  size?;

  @Prop({
    required: false,
  })
  customRanges?;

  @Prop({
    required: false,
    default: false
  })
  rangesOnly?;

  @Prop({
    required: false
  })
  showingFormat?;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  changeDatepickerLocale(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      const picker: any = this.$refs.picker;

      picker.locale.applyLabel = this.$t('datePicker.apply');
      picker.locale.cancelLabel = this.$t('datePicker.cancel');
      picker.locale.weekLabel = this.$t('datePicker.w');
      picker.locale.customRangeLabel = this.$t('datePicker.customRange');
      picker.locale.daysOfWeek = Info.weekdays('short');
      picker.locale.monthNames = Info.months('long');
    }
  }

  @Watch('startDate')
  startDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.dateRange.startDate = newVal;
    }
  }

  @Watch('endDate')
  endDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.dateRange.endDate = newVal;
    }
  }

  created() {
    this.dateRange.startDate = this.startDate ? this.datePrepare(this.startDate) : null;
    this.dateRange.endDate = this.endDate ? this.datePrepare(this.endDate) : null;
    this.initLocaleData();
    this.initRanges();
  }

  updateDateValues(dateRange: DateRangeValues) {
    const dateRangeStartDate = dateRange.startDate as Date;
    const dateRangeEndDate = dateRange.endDate as Date;
    const startDate = prepareDatepickerDate(dateRangeStartDate);
    const endDate = prepareDatepickerDate(dateRangeEndDate, { hour: 23, minute: 59, second: 59, millisecond: 59 });

    this.$emit('update:startDate', startDate);
    this.$emit('update:endDate', endDate);

    if (this.$listeners.update && isFunction(this.$listeners.update)) {
      this.$listeners.update(startDate, endDate);
    }
  }

  dateFormatter(value) {
    return value ? DateTime.fromJSDate(value).toFormat(this.showingFormat || DATE_FORMAT) : '';
  }

  datePrepare(value) {
    return DateTime.fromFormat(value, DATEPICKER_FORMAT).toJSDate();
  }

  initLocaleData() {
    const weekdays = Info.weekdays('short');
    this.localeData = {
      firstDay: 1,
      format: 'yyyy-mm-dd',
      applyLabel: this.$t('datePicker.apply'),
      cancelLabel: this.$t('datePicker.cancel'),
      weekLabel: this.$t('datePicker.w'),
      customRangeLabel: this.$t('datePicker.customRange'),
      daysOfWeek: [weekdays[weekdays.length - 1], ...weekdays.slice(0, weekdays.length - 1)],
      monthNames: Info.months('long'),
    };
  }

  initRanges() {
    const today = DateTime.local().toUTC().toJSDate();
    const yesterday = DateTime.local().toUTC().minus({ day: 1 }).toJSDate();
    const last7days = DateTime.local().toUTC().minus({ days: 6 }).toJSDate();
    const last30days = DateTime.local().toUTC().minus({ days: 29 }).toJSDate();
    const thisMonthStart = DateTime.local().toUTC().startOf('month').toJSDate();
    const thisMonthEnd = DateTime.local().toUTC().endOf('month').toJSDate();
    const prevMonthStart = DateTime.local().toUTC().minus({ month: 1 }).startOf('month').toJSDate();
    const prevMonthEnd = DateTime.local().toUTC().minus({ month: 1 }).endOf('month').toJSDate();

    this.ranges = {
      [this.$t('datePicker.today') as string]: [today, today],
      [this.$t('datePicker.yesterday') as string]: [yesterday, yesterday],
      [this.$t('datePicker.last7Days') as string]: [last7days, today],
      [this.$t('datePicker.last30Days') as string]: [last30days, today],
      [this.$t('datePicker.thisMonth') as string]: [thisMonthStart, thisMonthEnd],
      [this.$t('datePicker.lastMonth') as string]: [prevMonthStart, prevMonthEnd],
      [this.$t('datePicker.anyDates') as string]: [null, null],
    };
  }

  get classes() {
    return {
      [`form-control-${this.size}`]: this.size,
    };
  }
}
