import { Component, Prop, Vue } from 'vue-property-decorator';
import XlsxIcon from '@/components/ui/icons/xlsx-icon/xlsx-icon.vue';

@Component({
  components: { XlsxIcon }
})
export default class ExportXlsxBtn extends Vue {
  @Prop({
    required: false
  })
  count?: number;

  @Prop({
    required: false
  })
  title?: string;

  get totalCount() {
    return this.count || 0;
  }

  get btnTitle() {
    return this.title || `${this.$t('common.export')}: ${this.totalCount}`;
  }
}
