import { Component, Prop, Vue } from 'vue-property-decorator';
import ChartsPicker from '@/components/ui/charts-picker/charts-picker.vue';
import { ProductType } from '@/types/api/products/product.type';
import ImageCellRenderer from '@/components/ui/grid-table/cell/image-cell-renderer/image-cell-renderer.vue';
import { VBTooltip } from 'bootstrap-vue';

@Component({
  name: 'ProductBox',
  components: {
    ChartsPicker,
    ImageCellRenderer,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
})
export default class ProductBox extends Vue {

  @Prop({
    required: false
  })
  product?: ProductType

  @Prop({
    required: false
  })
  nameAttr?: string

  @Prop({
    type: String,
    required: false,
  })
  imgHeight!: string;

  @Prop({
    type: String,
    required: false,
  })
  imgWidth!: string;

  get productName() {
    return this.nameAttr
      ? this.product?.[this.nameAttr]
      : `${this.product?.displayName || this.product?.name || ''}`
  }

  get imageParams() {
    return {
      url: this.product?.imageUrl || null
    }
  }
}
