import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DATE_FORMAT, DATE_MODE_MONTHLY, getMonthName } from '@/helpers/date.helper';
import { ProductBrandType } from '@/types/api/reports/product-brand.type';
import { numberFormatter } from '@/helpers/string.helper';
import { COLOR_GREEN_5, COLOR_RED_5, COLOR_YELLOW_5 } from '@/constants/color.constant';
import ProductBox from '@/components/ui/product-box/product-box.vue';
import Box from '@/components/ui/box/box.vue';
import { DateTime } from 'luxon';

@Component({
  name: 'AcosTacosWidget',
  components: {
    ProductBox,
    Box,
  }
})
export default class AcosTacosWidget extends Vue {
  from = 20;
  to = 40;

  @Prop({ required: true })
  fromValue!: number;

  @Prop({ required: true })
  toValue!: number;

  @Prop({ required: true })
  productBrandReport!: ProductBrandType;

  @Prop({ required: true, type: Number })
  mode!: number;

  @Prop({ required: true, type: String })
  type!: string;

  @Prop({
    required: false,
    type: String,
    default: () => ''
  })
  title!: string;

  @Watch('from')
  fromChange(newVal: number) {
    this.$emit('update:from', newVal);
  }

  @Watch('to')
  toChange(newVal: number) {
    this.$emit('update:to', newVal);
  }

  @Watch('fromValue')
  fromValueChange(newVal: number) {
    this.from = newVal;
  }

  @Watch('toValue')
  toValueChange(newVal: number) {
    this.to = newVal;
  }

  created () {
    this.from = this.fromValue || this.from;
    this.to = this.toValue || this.to;
  }

  get productBrandAggregate() {
    return this.productBrandReport?.brands?.reduce((res: any[], item) => [...res, ...item.products], []) || [];
  }

  get productBrandDates() {
    return Object.keys(this.productBrandAggregate[0]?.statistics);
  }

  getDateTitle(value) {
    return this.mode === DATE_MODE_MONTHLY
      ? getMonthName(value)
      : DateTime.fromFormat(value, DATE_FORMAT).toFormat('dd.MM');
  }

  getProductValue(product, date) {
    const data = product.statistics[date];
    const result = this.type === 'tacos'
      ? data.totalSales ? (data.spend / data.totalSales) * 100 : 0
      : data.ppcSales ? (data.spend / data.ppcSales) * 100 : 0;

    return numberFormatter(result, { fractionPartCount: 0 });
  }

  get colors() {
    return [
      COLOR_RED_5,
      COLOR_GREEN_5,
      COLOR_YELLOW_5,
    ]
  }

  getColorStyle(value) {
    let backgroundColor = this.colors[0];

    if (value < this.from) {
      backgroundColor = this.colors[1];
    } else if (value >= this.from && value < this.to) {
      backgroundColor = this.colors[2];
    }

    return { backgroundColor }
  }
}
